import { combineReducers } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import notifications from "./slices/notifications";
import shoppingCart from "./slices/shoppingCart";
import itemModal from "./slices/itemModal";

const persistConfig = {
  key: "root",
  storage,
  whitelist: [
    "shoppingCart",
  ],
};

const combinedReducers = combineReducers({
  notifications: notifications.reducer,
  shoppingCart: shoppingCart.reducer,
  itemModal: itemModal.reducer,
});

export default persistReducer(persistConfig, combinedReducers);
