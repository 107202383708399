import React from "react";
import { Whatsapp } from "react-bootstrap-icons";
import useEnvConfig from "../../hooks/useEnvConfig";

const WAOverlay = () => {

  const waNumber = useEnvConfig("WHATSAPP_NUMBER");
  const waText = "Hola Artesanías Bernardo!";

  return (
    <>
      {waNumber && (
        <div
          className="position-fixed d-inline-block bottom-0 end-0"
          style={{
            zIndex: "1050",
          }}
        >
          <a
            className="d-flex justify-content-center align-items-center p-3 rounded-circle me-1 me-lg-4 mb-1 mb-lg-4 text-reset text-decoration-none"
            style={{
              backgroundColor: "#25d366",
            }}
            href={`https://api.whatsapp.com/send?phone=${waNumber}&text=${encodeURIComponent(waText)}`}
            target="_blank"
            rel="noreferrer"
          >
            <Whatsapp size="3.5rem" color="#ffffff" />
          </a>
        </div>
      )}
    </>
  );

};

export default WAOverlay;
