import React, { useState, useEffect } from "react";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { useAppSelector } from "../../hooks/useAppSelector";
import { hideModal } from "../../store/slices/itemModal";
import { addItem } from "../../store/slices/shoppingCart";
import Modal from "./Modal";
import { Button, Col, Row, Stack } from "react-bootstrap";
import { CartFill } from "react-bootstrap-icons";
import { addNotification } from "../../store/slices/notifications";
import { v4 as uuidv4 } from "uuid";

const ItemModal = () => {

  const dispatch = useAppDispatch();
  const itemToShow = useAppSelector((state) => state.itemModal.item);
  const showItemModal = useAppSelector((state) => state.itemModal.show);
  const [thumbnail, setThumbnail] = useState<string>("");

  useEffect(() => {
    if (!!itemToShow) {
      let imgUrl = "";
      try {
        imgUrl = require(`../../images/catalogue/${itemToShow.thumbnail}`)
      } catch (err) {
        console.error(`Could not load image ${itemToShow.thumbnail}`);
      }
      setThumbnail(imgUrl);
    }
  }, [itemToShow]);

  const handleHideItemModal = () => {
    dispatch(hideModal());
  };

  const handleAddItemToCart = () => {
    if (!!itemToShow) {
      dispatch(addItem(itemToShow));
      const notificationId = uuidv4();
      dispatch(addNotification({
        id: notificationId,
        type: "ShoppingCartNotification",
        itemName: itemToShow.title,
      }));
    }
    handleHideItemModal();
  };

  return (
    <>
      <Modal
        className="item-modal"
        title={(itemToShow !== null) ? itemToShow.title : ""}
        show={showItemModal}
        onHide={handleHideItemModal}
        footer={
          <>
            <Row className="g-0 gap-2 justify-content-start align-items-center mt-3">
              <Col xs="auto">
                <Button
                  variant="primary"
                  type="button"
                  onClick={handleAddItemToCart}
                >
                  Agregar al carrito<CartFill className="ms-1" size=".75em" />
                </Button>
              </Col>
              <Col xs="auto">
                <Button
                  variant="light"
                  type="button"
                  onClick={handleHideItemModal}
                >
                  Cerrar
                </Button>
              </Col>
            </Row>
          </>
        }
      >
        {!!itemToShow && (
          <Stack gap={3}>
            <div
              className="thumbnail rounded"
              style={{
                backgroundImage: `url(${thumbnail})`,
              }}
            >
            </div>
            {!!itemToShow.description && (
              <>
                <p className="mb-0">{itemToShow.description}</p>
                <p className="text-muted mb-0">Las medidas son de referencia y pueden variar.</p>
              </>
            )}
          </Stack>
        )}
      </Modal>
    </>
  );

};

export default ItemModal;
