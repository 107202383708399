import React from 'react';
import '../../styles/custom.scss';
import WAOverlay from './WAOverlay';
import Navbar from './Navbar';
import Content from './Content';
import Footer from './Footer';
import ItemModal from '../common/ItemModal';
import ScrollToTop from '../common/ScrollToTop';
import NotificationOverlay from './NotificationOverlay';

const Layout = () => {

  return (
    <>
      <ScrollToTop />
      <NotificationOverlay />
      <WAOverlay />
      <div className="d-flex flex-column h-100">
        <Navbar />
        <Content />
        <Footer />
      </div>
      <ItemModal />
    </>
  );

};

export default Layout;
