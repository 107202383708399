import React, { useState, useEffect } from "react";
import useWindowSize from "../../hooks/useWindowSize";
import type { ToastPosition } from "react-bootstrap/esm/ToastContainer";
import { useAppSelector } from "../../hooks/useAppSelector";
import ShoppingCartNotification, { type ShoppingCartNotificationProps } from "../common/notifications/ShoppingCartNotification";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { removeNotification } from "../../store/slices/notifications";

const NotificationOverlay = () => {

  const [position, setPosition] = useState<ToastPosition>("bottom-start");
  const [windowWidth] = useWindowSize();
  const dispatch = useAppDispatch();
  const notifications = useAppSelector((state) => state.notifications.stack);

  useEffect(() => {
    if (windowWidth >= 576) {
      setPosition("bottom-start");
    } else {
      setPosition("top-center");
    }
  }, [windowWidth]);

  const handleHideNotification = (id: string) => {
    setTimeout(() => dispatch(removeNotification(id)), 500);
  };

  return (
    <>
      {Object.keys(notifications).length > 0 && (
        <div className={`notification-overlay ${(
          position === "top-center" ?
            "top-center" :
            "bottom-start start-0"
        )} p-3`}>
          {Object.keys(notifications).map((notificationId) => {
            const notificationData = notifications[notificationId];
            let element = null;
            switch (notificationData.type) {
              case "ShoppingCartNotification":
                const elemData = notificationData as ShoppingCartNotificationProps;
                element = (
                  <ShoppingCartNotification
                    {...elemData}
                    key={notificationId}
                    onHide={() => handleHideNotification(notificationId)}
                  />
                );
                break;

              default:
                break;
            };

            return element;
          })}
        </div>
      )}
    </>
  );

};

export default NotificationOverlay;
