import React from "react";
import CenteredContentBox from "../components/common/CenteredContentBox";

const Tos = () => {

  return (
    <>
      <CenteredContentBox className="my-4">
        <h2 className="h3">Términos y Condiciones</h2>
        <p>Estos términos y condiciones de uso son válidos a partir de Junio de 2023.</p>
        <div>
          <p>Mates y Artesanías Bernardo describe, a través de este documento, las normas de uso del sitio web.</p>
          <p>Al navegar por este sitio web, consideramos que está de acuerdo con las condiciones de uso que figuran a continuación.</p>
          <p>Si no está de acuerdo con los términos de este acuerdo, le pedimos que no haga ningún otro uso de este sitio web.</p>
          <p>Si cambiamos nuestras condiciones de uso, publicaremos el nuevo texto en este sitio web, con una fecha de revisión actualizada. Podemos modificar este documento en cualquier momento.</p>
          <p>Si hay un cambio significativo en los términos de este acuerdo, podemos informarle mediante un mensaje mostrado al ingresar a este mismo sitio web.</p>
          <p>El uso de este sitio web después de los cambios significa que usted acepta las condiciones de uso revisadas. Si, después de leer la versión revisada, no está de acuerdo con sus términos, por favor, termine su acceso.</p>
          <ol>
            <li>
              <p>Uso del sitio web</p>
              <p>El uso de este sitio web le otorga automáticamente la condición de Usuario e implica su plena aceptación de todas las directrices y condiciones incluidas en estas Condiciones.</p>
            </li>
            <li>
              <p>Cookies</p>
              <p>La información sobre el uso que usted hace de este sitio web puede recogerse a través de las cookies. Las cookies son piezas de información que se almacenan directamente en el equipo que está utilizando. Las cookies permiten recoger información como el tipo de navegador, el tiempo de permanencia en el sitio web, las páginas visitadas, las preferencias de idioma y otros datos de tráfico anónimos. Nosotros y nuestros proveedores de servicios utilizamos la información para la protección de la seguridad, para facilitar la navegación, mostrar la información de manera más eficiente y personalizar su experiencia al utilizar este sitio web, así como para el seguimiento en línea. También recopilamos información estadística sobre el uso del sitio web para mejorar continuamente nuestro diseño y funcionalidad, para entender cómo se utiliza el sitio web y para ayudarle a resolver problemas relevantes.</p>
              <p>Si no desea que se recoja su información a través de las cookies, existe un procedimiento sencillo en la mayoría de los navegadores que permite rechazar automáticamente las cookies, o le da la opción de aceptar o rechazar la transferencia de una cookie específica (o varias) de un sitio web concreto a su ordenador. Sin embargo, esto puede crear inconvenientes en su uso del sitio web.</p>
              <p>La configuración que elija puede afectar a su experiencia de navegación y al funcionamiento que requiere el uso de cookies. En este sentido, rechazamos cualquier responsabilidad por las consecuencias derivadas del funcionamiento limitado de este sitio web causado por la desactivación de las cookies en su dispositivo (incapacidad para establecer o leer una cookie).</p>
            </li>
            <li>
              <p>Propiedad intelectual</p>
              <p>Todos los elementos de Mates y Artesanías Bernardo son propiedad intelectual de la misma. Estos términos o el uso del sitio web no le otorgan ninguna licencia o derecho de uso de los derechos de propiedad intelectual de Mates y Artesanías Bernardo o de cualquier tercero.</p>
            </li>
            <li>
              <p>Enlaces a sitios web de terceros</p>
              <p>Este sitio web puede contener, de vez en cuando, enlaces de hipertexto que le redirigirán a sitios web terceros. Si hace clic en uno de estos enlaces a cualquiera de estos sitios, recuerde que cada sitio tiene sus propias prácticas de privacidad y que nosotros no somos responsables de estas políticas. Por favor, consulte esas políticas antes de enviar cualquier dato personal a esos sitios.</p>
              <p>No somos responsables de las políticas y prácticas de recopilación, uso y divulgación (incluidas las prácticas de protección de datos) de otras organizaciones, como Facebook, Google, Microsoft, o cualquier otro desarrollador de software o proveedor de aplicaciones, tienda de medios sociales, sistema operativo, proveedor de servicios de internet o fabricante de dispositivos, incluidos los Datos Personales que usted divulgue a otras organizaciones a través de las aplicaciones, en relación con dichas aplicaciones, o publicados en nuestras redes sociales. Le recomendamos que se informe sobre la política de privacidad y las condiciones de uso de cada sitio web visitado o proveedor de servicios utilizado.</p>
            </li>
            <li>
              <p>Plazos y modificaciones</p>
              <p>El funcionamiento de este sitio web es por tiempo indefinido.</p>
              <p>La totalidad del sitio web o cada una de sus secciones podrá ser cerrada, suspendida o interrumpida unilateralmente por Mates y Artesanías Bernardo, en cualquier momento y sin previo aviso.</p>
            </li>
            <li>
              <p>Contacto</p>
              <p>Si tiene alguna pregunta sobre las condiciones de uso, por favor póngase en contacto con nosotros utilizando alguno de los medios listados en la sección de "Contacto" del sitio.</p>
            </li>
          </ol>
        </div>
      </CenteredContentBox>
    </>
  );

};

export default Tos;
