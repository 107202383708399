import React, { useState, useEffect } from "react";
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { Whatsapp, X } from "react-bootstrap-icons";
import { clear, removeItem } from "../../../store/slices/shoppingCart";
import { Button, Col, Row } from "react-bootstrap";
import Modal from "../../common/Modal";
import useEnvConfig from "../../../hooks/useEnvConfig";

const ShoppingCart = () => {

  const dispatch = useAppDispatch();
  const cartItems = useAppSelector((state) => state.shoppingCart.items);
  const [showCheckoutModal, setShowCheckoutModal] = useState<boolean>();
  const waNumber = useEnvConfig("WHATSAPP_NUMBER");
  const [waText, setWaText] = useState<string>("");

  useEffect(() => {
    if (cartItems.length > 0) {
      let waText = "Hola! Me gustaría hacer el siguiente encargo: ";
      const cartItemsCount = cartItems.length;
      for (let i = 0; i < cartItemsCount; i++) {
        let item = cartItems[i];

        waText += item.count + " " + item.title;

        if (i !== cartItemsCount - 1) {
          waText += " / ";
        }
      }
      waText += ". Muchas gracias!";
      setWaText(waText);
    } else {
      setWaText("");
    }
  }, [cartItems]);

  const handleRemoveItem = (itemTitle: string) => {
    dispatch(removeItem(itemTitle));
  };

  const handleCheckout = () => {
    setShowCheckoutModal(true);
  };

  const handleHideCheckoutModal = () => {
    setShowCheckoutModal(false);
  };

  return (
    <>
      {cartItems.length === 0 && (
        <p className="text-muted mb-0">Sin items</p>
      )}
      {cartItems.length > 0 && (
        <>
          <div className="cart-list gap-1">
            {cartItems.map((item, idx) => (
              <div className="cart-item gap-2" key={idx}>
                <p className="mb-0">
                  {item.count > 1 && (
                    <span className="me-1">({item.count})</span>
                  )}
                  <span>{item.title}</span>
                </p>
                <button
                  className="m-0 p-0 border-0 bg-transparent"
                  type="button"
                  onClick={() => handleRemoveItem(item.title)}
                >
                  <X size="1.25em" />
                </button>
              </div>
            ))}
          </div>
          <Row className="g-0 gap-2 justify-content-start align-items-center mt-3">
            <Col xs="auto">
              <Button
                variant="primary"
                type="submit"
                onClick={handleCheckout}
              >
                Realizar pedido
              </Button>
            </Col>
            <Col xs="auto">
              <Button
                variant="light"
                type="button"
                onClick={() => dispatch(clear())}
              >
                Limpiar
              </Button>
            </Col>
          </Row>
          <Modal
            title="Realizar pedido"
            show={showCheckoutModal}
            onHide={handleHideCheckoutModal}
            footer={
              <>
                <Row className="g-0 gap-2 justify-content-start align-items-center mt-3">
                  <Col xs="auto">
                    <a
                      className="d-flex justify-content-center align-items-center rounded px-3 py-1 text-reset text-decoration-none"
                      href={`https://api.whatsapp.com/send?phone=${waNumber}&text=${encodeURIComponent(waText)}`}
                      target="_blank"
                      rel="noreferrer"
                      style={{
                        backgroundColor: "#25d366",
                      }}
                    >
                      <Whatsapp color="white" size="2em" />
                    </a>
                  </Col>
                  <Col xs="auto">
                    <Button
                      variant="light"
                      type="button"
                      onClick={handleHideCheckoutModal}
                    >
                      Cancelar
                    </Button>
                  </Col>
                </Row>
              </>
            }
          >
            <p>Estás a punto de realizar el pedido de los siguientes items:</p>
            <ul>
              {cartItems.map((item, idx) => (
                <li key={idx}>{item.title} x{item.count}</li>
              ))}
            </ul>
            <p className="mb-0">Para terminar de realizar el pedido vamos a usar WhatsApp. Clickeá el botón de abajo para ir al chat.</p>
          </Modal>
        </>
      )}
    </>
  );

};

export default ShoppingCart;
