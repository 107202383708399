import React, { useState } from "react";
import { Navbar as RBNavbar, Container, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import Logo from '../../images/logo.png';

const Navbar = () => {
  const [expanded, setExpanded] = useState<boolean>(false);

  return (
    <>
      <RBNavbar
        bg="primary"
        expand="md"
        expanded={expanded}
        fixed="top"
        variant="light"
        className="shadow-sm"
      >
        <Container>
          <RBNavbar.Brand
            as={Link}
            to="/"
            reloadDocument
          >
            <img className="navbar-logo" src={Logo} alt="Mates y Artesanías Bernardo" />
          </RBNavbar.Brand>
          <RBNavbar.Toggle
            className="ms-auto"
            aria-controls="navbar-collapse"
            label="Mostrar/ocultar navegación"
            onClick={() => setExpanded(!expanded)}
          />
          <RBNavbar.Collapse id="navbar-collapse">
            <Nav className="ms-auto text-end mt-2 mt-lg-0 gap-2 gap-lg-0">
              <Nav.Link as={Link} to="/" onClick={() => setExpanded(false)}>Inicio</Nav.Link>
              <Nav.Link as={Link} to="/catalogo" onClick={() => setExpanded(false)}>Catálogo</Nav.Link>
              <Nav.Link as={Link} to="/curado" onClick={() => setExpanded(false)}>Curado</Nav.Link>
              <Nav.Link as={Link} to="/sobre-nosotros" onClick={() => setExpanded(false)}>Sobre nosotros</Nav.Link>
              <Nav.Link as={Link} to="/contacto" onClick={() => setExpanded(false)}>Contacto</Nav.Link>
            </Nav>
          </RBNavbar.Collapse>
        </Container>
      </RBNavbar>
    </>
  );

};

export default Navbar;
