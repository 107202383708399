import React from "react";
import { Spinner as RBSpinner } from "react-bootstrap";

interface SpinnerProps {
  [x: string]: any;
};

const Spinner = ({
  ...otherProps
}: SpinnerProps) => {

  return (
    <>
      <div className="d-flex align-items-center justify-content-center w-100 my-4">
        <RBSpinner animation="border" role="status" {...otherProps}>
          <span className="visually-hidden">Cargando...</span>
        </RBSpinner>
      </div>
    </>
  );

};

export default Spinner;
