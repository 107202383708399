import React, { useState } from "react";
import { Toast } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import type { Notification } from "../../../store/slices/notifications";

export interface ShoppingCartNotificationProps extends Notification {
  onHide: () => void;
  itemName: string;
};

const ShoppingCartNotification = ({
  id,
  onHide,
  itemName
}: ShoppingCartNotificationProps) => {

  const [show, setShow] = useState<boolean>(true);
  const location = useLocation();

  const hide = () => {
    if (!show) {
      return;
    }

    setShow(false);
    onHide();
  };

  return (
    <>
      <Toast show={show} onClose={hide} autohide delay={5000}>
        <Toast.Header closeButton={false}>
          <strong className="me-auto">¡Agregado al carrito!</strong>
          {(location.pathname !== "/catalogo") && (
            <small><Link to="/catalogo">Ver carrito</Link></small>
          )}
        </Toast.Header>
        <Toast.Body>
          {itemName} fue agregado al carrito.
        </Toast.Body>
      </Toast>
    </>
  );

};

export default ShoppingCartNotification;
