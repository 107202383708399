import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { CatalogueItem } from '../../hooks/useCatalogue';

interface ItemModalState {
  item: CatalogueItem | null;
  show: boolean;
};

const initialState: ItemModalState = {
  item: null,
  show: false,
};

const itemModal = createSlice({
  name: "itemModal",
  initialState: initialState,
  reducers: {
    showItem: (state, action: PayloadAction<CatalogueItem>) => {
      state.item = action.payload;
      state.show = true;
    },
    hideModal: (state) => {
      state.show = false;
    },
  },
});

export const {
  showItem,
  hideModal,
} = itemModal.actions;

export default itemModal;
