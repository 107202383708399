import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { CatalogueItem } from '../../hooks/useCatalogue';

type ShoppingCartItem = {
  title: string;
  count: number;
};

interface ShoppingCartState {
  items: ShoppingCartItem[];
};

const initialState: ShoppingCartState = {
  items: [],
};

const findItemIndexByName = (items: ShoppingCartItem[], title: string) => {
  return items.findIndex((item) => item.title === title);
};

const shoppingCart = createSlice({
  name: "shoppingCart",
  initialState: initialState,
  reducers: {
    addItem: (state, action: PayloadAction<CatalogueItem>) => {
      const itemIndex = findItemIndexByName(state.items, action.payload.title);
      if (itemIndex !== -1) {
        state.items[itemIndex].count++;
      } else {
        const cartItem = {
          title: action.payload.title,
          count: 1,
        };
        state.items.push(cartItem);
      }
    },
    removeItem: (state, action: PayloadAction<string>) => {
      const itemIndex = findItemIndexByName(state.items, action.payload);
      if (itemIndex !== -1) {
        const currentCount = state.items[itemIndex].count;
        if (currentCount > 1) {
          state.items[itemIndex].count--;
        } else {
          state.items.splice(itemIndex, 1);
        }
      }
    },
    clear: (state) => {
      state.items = [];
    },
  },
});

export const {
  addItem,
  removeItem,
  clear,
} = shoppingCart.actions;

export default shoppingCart;
