import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export type NotificationType = "ShoppingCartNotification";

export type Notification = {
  id: string;
  type: NotificationType;
  [x: string]: any;
};

interface NotificationsState {
  stack: {
    [id: string]: Notification;
  };
};

const initialState: NotificationsState = {
  stack: {},
};

const notifications = createSlice({
  name: "notifications",
  initialState: initialState,
  reducers: {
    addNotification: (state, action: PayloadAction<Notification>) => {
      state.stack[action.payload.id] = action.payload;
    },
    removeNotification: (state, action: PayloadAction<string>) => {
      if (state.stack.hasOwnProperty(action.payload)) {
        delete state.stack[action.payload];
      }
    },
    clearNotifications: (state) => {
      state.stack = {};
    },
  },
});

export const {
  addNotification,
  removeNotification,
  clearNotifications,
} = notifications.actions;

export default notifications;
