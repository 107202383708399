import React from "react";
import { Container } from "react-bootstrap";

interface ContentBoxProps {
  children?: React.ReactNode;
  className?: string;
  [x: string]: any;
}

const ContentBox = ({
  children,
  className,
  ...otherProps
}: ContentBoxProps) => {

  return (
    <>
      <Container
        className={`bg-white py-2 rounded-2 shadow-sm ${className}`}
        style={{
          paddingLeft: ".75rem",
          paddingRight: ".75rem",
        }}
        {...otherProps}
      >
        {children}
      </Container>
    </>
  );

};

export default ContentBox;
