import React from "react";
import CenteredContentBox from "../components/common/CenteredContentBox";

const Instructions = () => {

  return (
    <>
      <CenteredContentBox className="my-4">
        <h2 className="h3">Curado 🍃</h2>
        <p>A continuación te presentamos un pequeño instructivo respecto de cómo curar mates.</p>
        <h3 className="h4">Curado de mates de ñandubay</h3>
        <ol>
          <li>Aplicar una pequeña cantidad de manteca.</li>
          <li>Dejar reposar por 12 horas.</li>
          <li>Pasado el tiempo de reposo, pasar un papel de cocina o servilleta para quitar cualquier excedente.</li>
          <li>Dejarlo reposar con yerba usada o nueva (no hace falta humedecerla) por otras 12 horas.</li>
          <li>Listo para disfrutar.</li>
        </ol>
        <p>Se puede repetir el proceso de la yerba las veces que se desee.</p>
        <h3 className="h4">Curado de mates de algarrobo</h3>
        <ol>
          <li>Untar el mate por dentro con aceite neutro.</li>
          <li>Llenar el mate con yerba húmeda, sin agua. Puede ser yerba usada.</li>
          <li>Dejar reposar por 8 horas.</li>
          <li>Pasado el tiempo de reposo, descartar la yerba.</li>
          <li>Enjuagar con agua óptima para empezar a cebar.</li>
          <li>Listo para disfrutar.</li>
        </ol>
        <p className="mb-0">Para que la temperatura de tu mate esté en su punto justo, asegurate de que no esté ni tibia ni hirviendo. Lo aconsejable es que esté entre 70° y 80° C.</p>
      </CenteredContentBox>
    </>
  );

};

export default Instructions;
