import React from 'react';
import CenteredContentBox from '../components/common/CenteredContentBox';
import { Col, Row, Stack } from 'react-bootstrap';
import MateRustico from '../images/about-us/mate_rustico.jpg';
import MateTallado from '../images/about-us/mate_tallado.jpg';

const AboutUs = () => {

  return (
    <>
      <CenteredContentBox className="my-4">
        <h2 className="h3">Sobre nosotros 🧉</h2>
        <p className="lead">Mates y Artesanías Bernardo</p>
        <p className="mb-1">Somos un emprendimiento de Buenos Aires, Argentina. Confeccionamos <strong>mates, azucareras, yerberas y otras artesanías</strong>. Cada pieza terminada es <strong>única e irrepetible</strong>, tanto por lo que revela cada trozo de madera como también por su extraordinario encanto natural.</p>
        <p className="mb-0">Trabajamos con <strong>madera de ñandubay</strong>, caracterizada por ser resistente, duradera y naturalmente estética. Debajo listamos los distintos estilos de productos que ofrecemos:</p>
        <Stack className="product-styles gap-4 my-4 px-md-5 mx-lg-5">
          <Row className="g-0 gap-2">
            <Col sm>
              <h3 className="h4">Rústico</h3>
              <p>Son aquellos productos realizados con la materia prima como se encuentra en la naturaleza, en forma de troncos o ramas. Se trabaja principalmente con la corteza.</p>
            </Col>
            <Col sm="auto" className="d-flex flex-column align-items-center">
              <img src={MateRustico} className="rounded-2" alt="Un mate de estilo rústico" />
            </Col>
          </Row>
          <Row className="g-0 gap-2">
            <Col sm>
              <h3 className="h4">Tallado</h3>
              <p>El taco de madera se va torneando de manera tal que gran parte de la corteza descubre la belleza escondida de las vetas y los colores típicos del ñandubay.</p>
            </Col>
            <Col sm="auto" className="d-flex flex-column align-items-center">
              <img src={MateTallado} className="rounded-2" alt="Un mate de estilo tallado" />
            </Col>
          </Row>
        </Stack>
        <p>El tamaño de cada producto es similar, por lo que puede presentar pequeñas variaciones de altura o de ancho. Esto se debe a que todo se tornea, se realiza y termina a mano.</p>
        <p className="mb-1">¡Llevate una de nuestras artesanías como regalo de cumpleaños, aniversario o para darte un gusto!</p>
        <p className="mb-0">Mates y Artesanías Bernardo ¡Bien Argentino!</p>
      </CenteredContentBox>
    </>
  );

};

export default AboutUs;
