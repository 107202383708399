import React from "react";
import Carousel from "../../common/Carousel";

interface HomeCarouselItemProps {
  title: string;
  description: string;
  imgUrl: string;
};

interface HomeCarouselProps {
  items: HomeCarouselItemProps[];
};

const HomeCarousel = ({
  items,
}: HomeCarouselProps) => {

  return (
    <>
      <Carousel className="home-carousel">
        {items.map((item, idx) => {
          const isFirstItem = (idx === 0);
          return (
            <Carousel.Item key={idx}>
              <div
                className="home-carousel-img"
                style={{
                  backgroundImage: `url(${item.imgUrl})`
                }}
              >
                <span className="visually-hidden">Imagen de {item.title}</span>
              </div>
              <Carousel.Caption>
                <h2 className={!isFirstItem ? "h3" : ""}>{item.title}</h2>
                <p className={isFirstItem ? "lead" : ""}>{item.description}</p>
              </Carousel.Caption>
            </Carousel.Item>
          );
        })}
      </Carousel>
    </>
  );

};

export default HomeCarousel;
