import React, { useState, useEffect } from 'react';
import { Button, Col, Container, Row, Stack } from 'react-bootstrap';
import ContentBox from '../components/common/ContentBox';
import useFunFacts from '../hooks/useFunFacts';
import HomeCarousel from '../components/pages/Home/HomeCarousel';
import ProductCard from '../components/common/ProductCard';
import { Link } from 'react-router-dom';
import useCatalogue, { CatalogueItem } from '../hooks/useCatalogue';
import Banner1 from '../images/banner1.png';
import Banner2 from '../images/banner2.png';
import Banner3 from '../images/banner3.png';

const previewItemsCount = 4;

const Home = () => {

  const funFact = useFunFacts();
  const catalogue = useCatalogue();
  const [previewItems, setPreviewItems] = useState<CatalogueItem[]>([]);

  useEffect(() => {
    const randomIndex = (length: number) => {
      return Math.floor(Math.random() * length);
    };

    const customCatalogue = JSON.parse(JSON.stringify(catalogue));
    const newItems: CatalogueItem[] = [];
    if (customCatalogue.length > 0) {
      for (let i = 0; i < previewItemsCount; i++) {
        const index = randomIndex(customCatalogue.length);
        const newItemArr = customCatalogue.splice(index, 1);
        if (newItemArr.length === 1) {
          newItems.push(newItemArr[0]);
        }
      }
    }

    setPreviewItems(newItems);
  }, [catalogue]);

  return (
    <>
      <div className="d-flex flex-column mb-4">
        <HomeCarousel
          items={[
            {
              title: "Mates y Artesanías Bernardo",
              description: "¡Bien Argentino!",
              imgUrl: Banner1,
            },
            {
              title: "Únicos e irrepetibles",
              description: "Nuestros productos son artesanales, hechos enteramente a mano.",
              imgUrl: Banner2,
            },
            {
              title: "El regalo perfecto",
              description: "Llevate el regalo para esa persona especial, un recuerdo o date un gusto con lo que tenemos para ofrecerte.",
              imgUrl: Banner3,
            },
          ]}
        />
        <Container className="mt-4">
          <Stack gap={3}>
            <h3 className="mb-0">Especialmente para vos 😊</h3>
            <Row className="justify-content-center align-items-stretch">
              {previewItems.map((item, idx) => (
                <Col
                  sm={6}
                  lg={3}
                  className={`${(idx > 0) ? `mt-4 ${(idx < 2) ? "mt-sm-0" : (idx < 4) ? "mt-lg-0" : ""}` : ""}`}
                  key={idx}
                >
                  <ProductCard item={item}/>
                </Col>
              ))}
            </Row>
          </Stack>
        </Container>
        <Container fluid className="mt-4 p-0 bg-dark text-light">
          <Container className="py-4">
            <Stack gap={3}>
              <h3>Un catálogo completo 📖</h3>
              <p className="mb-0">Disfrutá de nuestro catálogo, donde vas a encontrar productos de primera calidad hechos con mucho amor.</p>
              <Link to="/catalogo" className="d-flex flex-column align-items-stretch text-decoration-none">
                <Button variant="primary">
                  <span className="lead">Ver catálogo</span>
                </Button>
              </Link>
            </Stack>
          </Container>
        </Container>
        <ContentBox className="mt-4">
          <Stack gap={3}>
            <h3>Sabías que... 🤔</h3>
            <p className="mb-0">{funFact}</p>
          </Stack>
        </ContentBox>
        <Container fluid className="mt-4 p-0 bg-dark text-light">
          <Container className="py-4">
            <Stack gap={3}>
              <h3>Instructivo de curado 🍃</h3>
              <p className="mb-0">Tenés a disposición un instructivo de curado muy interesante para que tus mates mantengan la calidad como el primer día.</p>
              <Link to="/curado" className="d-flex flex-column align-items-stretch text-decoration-none">
                <Button variant="primary">
                  <span className="lead">Ver instructivo</span>
                </Button>
              </Link>
            </Stack>
          </Container>
        </Container>
      </div>
    </>
  );

};

export default Home;
