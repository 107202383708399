import { useEffect, useState } from "react";
import catalogueJson from '../data/catalogue.json';

export type CatalogueItemTags = {
  product?: string;
  productType?: string;
  model?: string;
  size?: string;
};

export type CatalogueItem = {
  title: string;
  thumbnail: string;
  description?: string;
  tags?: CatalogueItemTags;
};

interface Catalogue {
  all: CatalogueItem[];
};

const useCatalogue = () => {

  const [catalogue] = useState<Catalogue>(catalogueJson);
  const [items, setItems] = useState<CatalogueItem[]>([]);

  useEffect(() => {
    const allItems = catalogue.all;
    setItems(allItems);
  }, [catalogue]);

  return items;

};

export default useCatalogue;
