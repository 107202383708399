import React from 'react';
import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';
import Layout from './components/layout/Layout';
import Error from './pages/Error';
import Home from './pages/Home';
import Catalogue from './pages/Catalogue';
import AboutUs from './pages/AboutUs';
import Contact from './pages/Contact';
import { Provider } from 'react-redux';
import store from './store';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import Instructions from './pages/Instructions';
import Tos from './pages/Tos';

const persistor = persistStore(store);

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route
      element={<Layout />}
      errorElement={<Error />}
    >
      <Route
        path="/tyc"
        element={<Tos />}
      />
      <Route
        path="/"
        element={<Home />}
      />
      <Route
        path="/catalogo"
        element={<Catalogue />}
      />
      <Route
        path="/curado"
        element={<Instructions />}
      />
      <Route
        path="/sobre-nosotros"
        element={<AboutUs />}
      />
      <Route
        path="/contacto"
        element={<Contact />}
      />
    </Route>
  )
);

const App = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <RouterProvider router={router} />
      </PersistGate>
    </Provider>
  );
};

export default App;
