import React, { useState, useEffect } from "react";
import { Carousel as RBCarousel } from "react-bootstrap";

interface CarouselProps {
  children?: React.ReactNode;
  [x: string]: any;
};

const Carousel = ({
  children,
  ...otherProps
}: CarouselProps) => {
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [indicatorLabels, setIndicatorLabels] = useState<string[]>([]);

  useEffect(() => {
    if (children && Array.isArray(children)) {
      setIndicatorLabels(children.map((el, idx) => `Imagen #${idx + 1}`));
    } else {
      setIndicatorLabels([]);
    }
  }, [children]);

  const handleActiveIndexChange = (newIndex: number) => {
    setActiveIndex(newIndex);
  };

  return (
    <>
      <RBCarousel
        activeIndex={activeIndex}
        onSelect={handleActiveIndexChange}
        indicatorLabels={indicatorLabels}
        nextLabel="Siguiente"
        prevLabel="Anterior"
        {...otherProps}
      >
        {children}
      </RBCarousel>
    </>
  );

};

Carousel.Item = RBCarousel.Item;
Carousel.Caption = RBCarousel.Caption;

export default Carousel;
