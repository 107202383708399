import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";

// FORM
interface FormFields {
  buscar: string;
  producto: string;
  "tipo-producto": string;
  modelo: string;
  tamano: string;
};

interface FormErrors extends Partial<FormFields> {};

const initialFormValues: FormFields = {
  buscar: "",
  producto: "",
  "tipo-producto": "",
  modelo: "",
  tamano: "",
};


// FILTER OPTIONS
const productOptions = {
  mate: "Mate",
  "yerbera-azucarera": "Yerbera / Azucarera",
  mortero: "Mortero",
  cenicero: "Cenicero",
  cuenco: "Cuenco",
  bombilla: "Bombilla",
  combo: "Combo",
};

const productTypeOptions = {
  rustico: "Rústico",
  tallado: "Tallado",
  bambu: "Bambú",
  metal: "Metal",
};

const modelOptions = {
  vaso: "Vaso",
  copa: "Copa",
};

const sizeOptions = {
  chico: "Chico",
  mediano: "Mediano",
  grande: "Grande",
};

const parameterOptionsMap = {
  producto: productOptions,
  "tipo-producto": productTypeOptions,
  modelo: modelOptions,
  tamano: sizeOptions,
};


// PROPS
export interface OnApplyFiltersFilters extends Partial<FormFields> {};

interface FiltersProps {
  onApplyFilters: (filters: OnApplyFiltersFilters) => void;
  onResetFilters: () => void;
};

const Filters = ({
  onApplyFilters,
  onResetFilters
}: FiltersProps) => {

  const [searchParams, setSearchParams] = useSearchParams();
  const [formValues, setFormValues] = useState<FormFields>(initialFormValues);

  useEffect(() => {
    const newFormValues: FormFields = {...initialFormValues};
    const filters: OnApplyFiltersFilters = {};
    Object.keys(initialFormValues).forEach((key) => {
      const value = searchParams.get(key);
      if (value !== null) {
        newFormValues[key as keyof typeof initialFormValues] = value;
        filters[key as keyof typeof initialFormValues] = value;
      }
    });
    if (JSON.stringify(newFormValues) !== JSON.stringify(formValues)) {
      setFormValues(newFormValues);
      onApplyFilters(filters);
    }
  }, [searchParams, formValues, onApplyFilters]);

  const handleResetClick = () => {
    setSearchParams();
    onResetFilters();
  };

  return (
    <>
      <Formik
        initialValues={formValues}
        enableReinitialize={true}
        validate={values => {
          const errors: FormErrors = {};

          for (const param in parameterOptionsMap) {
            const value = values[param as keyof FormFields];

            if (!!value) {
              const options = parameterOptionsMap[param as keyof typeof parameterOptionsMap];

              if (!options.hasOwnProperty(value)) {
                errors[param as keyof FormErrors] = "Seleccione una opción válida";
              }
            }
          }

          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          const params = Object.entries(values).filter(entry => !!entry[1]);
          setSearchParams(params);
          setSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          handleChange,
          handleSubmit,
          isSubmitting,
        }) => (
          <Form action="/catalogo" method="GET" onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="search">
              <Form.Control
                type="text"
                placeholder="Buscar"
                name="buscar"
                value={values.buscar}
                onChange={handleChange}
              />
              {errors.buscar && (
                <Form.Text className="text-danger">
                  {errors.buscar}
                </Form.Text>
              )}
            </Form.Group>
            <Form.Group className="mb-3" controlId="product">
              <Form.Label>Seleccionar producto</Form.Label>
              <Form.Select
                name="producto"
                value={values.producto}
                onChange={handleChange}
              >
                <option value="">Cualquiera</option>
                {Object.entries(productOptions).map(([key, value], idx) => (
                  <option value={key} key={idx}>{value}</option>
                ))}
              </Form.Select>
              {errors.producto && (
                <Form.Text className="text-danger">
                  {errors.producto}
                </Form.Text>
              )}
            </Form.Group>
            <Form.Group className="mb-3" controlId="product-type">
              <Form.Label>Seleccionar tipo de producto</Form.Label>
              <Form.Select
                name="tipo-producto"
                value={values["tipo-producto"]}
                onChange={handleChange}
              >
                <option value="">Cualquiera</option>
                {Object.entries(productTypeOptions).map(([key, value], idx) => (
                  <option value={key} key={idx}>{value}</option>
                ))}
              </Form.Select>
              {errors["tipo-producto"] && (
                <Form.Text className="text-danger">
                  {errors["tipo-producto"]}
                </Form.Text>
              )}
            </Form.Group>
            <Form.Group className="mb-3" controlId="model">
              <Form.Label>Seleccionar modelo</Form.Label>
              <Form.Select
                name="modelo"
                value={values.modelo}
                onChange={handleChange}
              >
                <option value="">Cualquiera</option>
                {Object.entries(modelOptions).map(([key, value], idx) => (
                  <option value={key} key={idx}>{value}</option>
                ))}
              </Form.Select>
              {errors.modelo && (
                <Form.Text className="text-danger">
                  {errors.modelo}
                </Form.Text>
              )}
            </Form.Group>
            <Form.Group className="mb-3" controlId="size">
              <Form.Label>Seleccionar tamaño</Form.Label>
              <Form.Select
                name="tamano"
                value={values.tamano}
                onChange={handleChange}
              >
                <option value="">Cualquiera</option>
                {Object.entries(sizeOptions).map(([key, value], idx) => (
                  <option value={key} key={idx}>{value}</option>
                ))}
              </Form.Select>
              {errors.tamano && (
                <Form.Text className="text-danger">
                  {errors.tamano}
                </Form.Text>
              )}
            </Form.Group>
            <Row className="g-0 gap-2 justify-content-start align-items-center">
              <Col xs="auto">
                <Button
                  variant="primary"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Aplicar
                </Button>
              </Col>
              <Col xs="auto">
                <Button
                  variant="light"
                  type="button"
                  disabled={isSubmitting}
                  onClick={handleResetClick}
                >
                  Reiniciar
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </>
  );

};

export default Filters;
