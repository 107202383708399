import React from "react";
import ContentBox from "./ContentBox";

interface CenteredContentBoxProps {
  children?: React.ReactNode;
  className?: string;
  [x: string]: any;
}

const CenteredContentBox = ({
  children,
  className,
  ...otherProps
}: CenteredContentBoxProps) => {

  return (
    <>
      <div className="d-flex flex-column h-100 align-items-center justify-content-center">
        <ContentBox className={className} {...otherProps}>
          {children}
        </ContentBox>
      </div>
    </>
  );

};

export default CenteredContentBox;
