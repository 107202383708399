import React from 'react';
import { Link, isRouteErrorResponse, useRouteError } from 'react-router-dom';
import Navbar from '../components/layout/Navbar';
import Footer from '../components/layout/Footer';
import { Container } from 'react-bootstrap';

const Error = () => {
  const error = useRouteError();
  const isRouteError = isRouteErrorResponse(error);
  const status = isRouteError ? error.status : 0;

  const getErrorTitle = () => {
    return `¡Error${(status > 0) ? ` ${status}` : ""}!`;
  };

  const getErrorDescription = () => {
    switch (status) {
      case 404:
        return "La página que estaba buscando no se pudo encontrar.";

      default:
        return "Ocurrió un error inesperado. Lamentamos las molestias.";
    };
  };

  return (
    <>
      <div className="d-flex flex-column h-100">
        <Navbar />
        <main className="content">
          <div className="d-flex justify-content-center align-items-center h-100">
            <Container className="px-lg-5">
              <h1 className="h2">{getErrorTitle()}</h1>
              <p className="lead">{getErrorDescription()}</p>
              <Link to="/">Volver al inicio</Link>
            </Container>
          </div>
        </main>
        <Footer />
      </div>
    </>
  );

};

export default Error;
