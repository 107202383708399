import React, { useEffect, useState } from "react";
import { Button, ButtonGroup, Col, Row } from "react-bootstrap";
import { CaretLeftFill, CaretRightFill } from "react-bootstrap-icons";

interface PaginateProps {
  children?: React.ReactNode;
  currentPage: number;
  totalPages: number;
  onPageChange: (newPage: number) => void;
};

const maxNumberOfButtons = 4;

const Paginate = ({
  children,
  currentPage,
  totalPages,
  onPageChange,
}: PaginateProps) => {

  const [numberOfButtons, setNumberOfButtons] = useState<number>(maxNumberOfButtons);
  const [firstPage, setFirstPage] = useState<number>(1);

  useEffect(() => {
    if (currentPage < 1 || currentPage > totalPages) {
      if (currentPage < 1) {
        onPageChange(1);
      } else {
        onPageChange(totalPages);
      }
    } else {
      const newNumberOfButtons = (totalPages < maxNumberOfButtons) ? totalPages : maxNumberOfButtons;
      setNumberOfButtons(newNumberOfButtons);

      const middleButtonIndex = Math.ceil(newNumberOfButtons / 2) - 1;
      const evenFactor = (newNumberOfButtons % 2 === 0) ? 1 : 0;
      if (currentPage - middleButtonIndex <= 1) {
        setFirstPage(1);
      } else if (currentPage + middleButtonIndex + evenFactor >= totalPages) {
        setFirstPage(totalPages - newNumberOfButtons + 1);
      } else {
        setFirstPage(currentPage - middleButtonIndex);
      }
    }
  }, [currentPage, totalPages, onPageChange]);

  const handleButtonClick = (newPage: number) => {
    if (newPage < 1 || newPage > totalPages || newPage === currentPage) {
      return;
    }

    onPageChange(newPage);
  };

  return (
    <>
      {children}
      {totalPages > 1 && (
        <Row className="paginate justify-content-center align-items-center g-0 gap-2 mt-4">
          {currentPage > 1 && (
            <Col xs="auto" className="d-flex justify-content-center justify-content-xs-end">
              <Button variant="secondary" onClick={() => handleButtonClick(currentPage - 1)}>
                <CaretLeftFill size="0.8em" />
                <span className="visually-hidden">Anterior</span>
              </Button>
            </Col>
          )}
          <Col xs="auto" className="d-flex justify-content-center">
            <ButtonGroup className="d-flex gap-1">
              {[...Array(numberOfButtons)].map((el, idx) => {
                const buttonPage = firstPage + idx;
                return (
                  <Button
                    key={idx}
                    variant={(buttonPage === currentPage) ? "primary" : "secondary"}
                    onClick={() => handleButtonClick(buttonPage)}
                  >
                    {buttonPage}
                  </Button>
                );
              })}
            </ButtonGroup>
          </Col>
          {currentPage < totalPages && (
            <Col xs="auto" className="d-flex justify-content-center justify-content-xs-start">
              <Button variant="secondary" onClick={() => handleButtonClick(currentPage + 1)}>
                <CaretRightFill size="0.8em" />
                <span className="visually-hidden">Siguiente</span>
              </Button>
            </Col>
          )}
        </Row>
      )}
    </>
  );

};

export default Paginate;
