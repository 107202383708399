import { Modal as RBModal } from 'react-bootstrap';

interface ModalProps {
  children: React.ReactNode;
  title: string;
  footer?: React.ReactNode;
  [x: string]: any;
};

const Modal = ({
  children,
  title,
  footer = null,
  ...otherProps
}: ModalProps) => {

  return (
    <>
      <RBModal
        {...otherProps}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <RBModal.Header>
          <RBModal.Title id="contained-modal-title-vcenter">
            {title}
          </RBModal.Title>
        </RBModal.Header>
        <RBModal.Body>
          {children}
        </RBModal.Body>
        {!!footer && (
          <RBModal.Footer>
            {footer}
          </RBModal.Footer>
        )}
      </RBModal>
    </>
  );

};

export default Modal;
