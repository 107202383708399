import React from 'react';
import CenteredContentBox from '../components/common/CenteredContentBox';
import { Stack } from 'react-bootstrap';
import useEnvConfig from '../hooks/useEnvConfig';
import { Facebook, Instagram, Whatsapp } from 'react-bootstrap-icons';

const Contact = () => {

  const igUrl = useEnvConfig("INSTAGRAM_URL");
  const fbUrl = useEnvConfig("FACEBOOK_URL");
  const waNumber = useEnvConfig("WHATSAPP_NUMBER");
  const waText = "Hola Artesanías Bernardo!";

  const renderContactInfo = () => {
    if (!igUrl && !fbUrl && !waNumber) {
      return (
        <p className="mb-0">No pudimos obtener datos de contacto. Lamentamos las molestias.</p>
      );
    }

    return (
      <>
        {igUrl && (
          <a href={igUrl} target="_blank" rel="noreferrer">
            <Instagram size="1.25em" color="#d62976" /> Instagram
          </a>
        )}
        {fbUrl && (
          <a href={fbUrl} target="_blank" rel="noreferrer">
            <Facebook size="1.25em" color="#3b5998" /> Facebook
          </a>
        )}
        {waNumber && (
          <a href={`https://api.whatsapp.com/send?phone=${waNumber}&text=${encodeURIComponent(waText)}`} target="_blank" rel="noreferrer">
            <Whatsapp size="1.25em" color="#25d366" /> WhatsApp
          </a>
        )}
      </>
    );
  };

  return (
    <>
      <CenteredContentBox className="my-4">
        <h2 className="h3">Contacto ✉️</h2>
        <p className="mb-0">Podés contactarnos por cualquiera de los siguientes medios:</p>
        <Stack className="gap-3 mx-md-3 mx-lg-5 my-4" style={{fontSize: "1.25rem"}}>
          {renderContactInfo()}
        </Stack>
        <p className="mb-0">¡Dejanos tu consulta o cualquier duda que tengas!</p>
      </CenteredContentBox>
    </>
  );

};

export default Contact;
