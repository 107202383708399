import React, { useState, useEffect } from "react";
import { Button, Ratio, Stack } from "react-bootstrap";
import { CatalogueItem } from "../../hooks/useCatalogue";
import { Link } from "react-router-dom";
import { CartFill } from "react-bootstrap-icons";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { addItem } from "../../store/slices/shoppingCart";
import { showItem } from "../../store/slices/itemModal";
import { addNotification } from "../../store/slices/notifications";
import { v4 as uuidv4 } from "uuid";

export const propertyToFieldMap = {
  "product": "producto",
  "productType": "tipo-producto",
  "model": "modelo",
  "size": "tamano",
};

interface ProductCardProps {
  item: CatalogueItem;
};

const ProductCard = ({
  item,
}: ProductCardProps) => {

  const [thumbnail, setThumbnail] = useState<string>("");
  const [tags, setTags] = useState<object|null>(null);
  const dispatch = useAppDispatch();

  useEffect(() => {
    let imgUrl = "";
    try {
      imgUrl = require(`../../images/catalogue/${item.thumbnail}`)
    } catch (err) {
      console.error(`Could not load image ${item.thumbnail}`);
    }
    setThumbnail(imgUrl);
    setTags(item.tags || null);
  }, [item]);

  const getCatalogueUrl = (property: string, value: string) => {
    if (!propertyToFieldMap.hasOwnProperty(property)) {
      return "/catalogo";
    }

    const field = propertyToFieldMap[property as keyof typeof propertyToFieldMap];
    const params = {
      [field]: value,
    };
    const queryParams = new URLSearchParams(params).toString();
    return `/catalogo?${queryParams}`;
  };

  const handleAddToCart = () => {
    dispatch(addItem(item));
    const notificationId = uuidv4();
    dispatch(addNotification({
      id: notificationId,
      type: "ShoppingCartNotification",
      itemName: item.title,
    }));
  };

  const handleShowItemModal = () => {
    dispatch(showItem(item));
  };

  return (
    <>
      <div className="product-card bg-secondary rounded-2 p-3 shadow-sm h-100">
        <div className="d-flex justify-content-between flex-column h-100">
          <Stack gap={2}>
            <Ratio aspectRatio="1x1">
              <div
                className="product-img rounded-2"
                style={{
                  backgroundImage: `url(${thumbnail})`,
                }}
                onClick={handleShowItemModal}
              >
                <span className="visually-hidden">Imagen de {item.title}</span>
              </div>
            </Ratio>
            <h4
              className="h5 fw-bold mb-0"
              style={{
                cursor: "pointer"
              }}
              onClick={handleShowItemModal}
            >{item.title}</h4>
            {(tags && Object.keys(tags).length > 0) && (
              <div className="d-flex flex-wrap gap-2">
                {Object.keys(tags).map((tag, idx) => (
                  <p className="text-muted mb-0" key={idx}>
                    {propertyToFieldMap.hasOwnProperty(tag) && (
                      <Link className="tag-link" to={getCatalogueUrl(tag, tags[tag as keyof typeof tags])}>#{tags[tag as keyof typeof tags]}</Link>
                    )}
                    {!propertyToFieldMap.hasOwnProperty(tag) && (
                      <span>#{tags[tag as keyof typeof tags]}</span>
                    )}
                  </p>
                ))}
              </div>
            )}
          </Stack>
          <Button
            className="product-button mt-2"
            type="button"
            variant="secondary"
            onClick={handleAddToCart}
          >
            <span>Agregar al carrito<CartFill className="ms-1" size=".75em" /></span>
          </Button>
        </div>
      </div>
    </>
  );

};

export default ProductCard;
