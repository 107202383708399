import React from "react";
import { Container, Row, Col, Stack, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Instagram, Facebook } from "react-bootstrap-icons";
import useEnvConfig from "../../hooks/useEnvConfig";

const Footer = () => {

  const igUrl = useEnvConfig("INSTAGRAM_URL");
  const fbUrl = useEnvConfig("FACEBOOK_URL");
  const copyYear = new Date().getFullYear();

  return (
    <>
      <footer className="footer py-4 px-2 px-lg-4 shadow">
        <Container className="mb-5 mb-md-0">
          <Row className="g-0 gap-4 gap-md-0">
            <Col md={4} className="my-md-auto">
              <Stack className="align-items-center" gap={2}>
                <h3 className="h5 fw-bold">Mates y Artesanías Bernardo</h3>
                {(igUrl || fbUrl) && (
                  <Row className="g-0 gap-2 justify-content-center">
                    {igUrl && (
                      <Col xs="auto">
                        <a href={igUrl} target="_blank" rel="noreferrer">
                          <Instagram size="1em" color="#000000" /> Instagram
                        </a>
                      </Col>
                    )}
                    {fbUrl && (
                      <Col xs="auto">
                        <a href={fbUrl} target="_blank" rel="noreferrer">
                          <Facebook size="1em" color="#000000" /> Facebook
                        </a>
                      </Col>
                    )}
                  </Row>
                )}
              </Stack>
            </Col>
            <Col md={8} className="my-md-auto">
              <Row className="g-0 gap-3 gap-md-0">
                <Col md className="my-md-auto">
                  <Nav className="d-flex flex-column align-items-center">
                    <Nav.Item>
                      <Nav.Link as={Link} to="/">Inicio</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link as={Link} to="/catalogo">Catálogo</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link as={Link} to="/curado">Curado</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link as={Link} to="/sobre-nosotros">Sobre nosotros</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link as={Link} to="/contacto">Contacto</Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Col>
                <Col md className="my-md-auto">
                  <div className="flex flex-column text-center">
                    <p className="mb-2">Artesanías Bernardo &copy; {copyYear}</p>
                    <p className="mb-0"><Link to="/tyc">Términos y condiciones</Link></p>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );

};

export default Footer;
