import { useEffect, useState } from "react";
import funFactsJson from '../data/fun-facts.json';

interface FunFacts {
  all: string[];
};

const useFunFacts = () => {

  const [funFacts] = useState<FunFacts>(funFactsJson);
  const [text, setText] = useState<string>("");

  useEffect(() => {
    const allFunFacts = funFacts.all;
    const textIndex = Math.floor(Math.random() * allFunFacts.length);
    setText(allFunFacts[textIndex]);
  }, [funFacts]);

  return text;

};

export default useFunFacts;
