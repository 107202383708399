import React, { useEffect, useState } from "react";
import { Col, Collapse, Container, Row } from "react-bootstrap";
import ContentBox from "../components/common/ContentBox";
import ProductCard, { propertyToFieldMap } from "../components/common/ProductCard";
import Filters, { OnApplyFiltersFilters } from "../components/pages/Catalogue/Filters";
import { CaretDownFill, CaretUpFill, CartFill, FunnelFill } from "react-bootstrap-icons";
import useWindowSize from "../hooks/useWindowSize";
import useCatalogue, { CatalogueItem, CatalogueItemTags } from "../hooks/useCatalogue";
import Paginate from "../components/common/Paginate";
import ShoppingCart from "../components/pages/Catalogue/ShoppingCart";
import Spinner from "../components/common/Spinner";

export const fieldToPropertyMap = Object.fromEntries(Object.entries(propertyToFieldMap).map(p => p.reverse()));

const itemsPerPage = 8;

const Catalogue = () => {

  const [expandedFilters, setExpandedFilters] = useState<boolean>(true);
  const [windowWidth] = useWindowSize();
  const catalogue = useCatalogue();
  const [filters, setFilters] = useState<OnApplyFiltersFilters>({});
  const [items, setItems] = useState<CatalogueItem[]>(catalogue);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (windowWidth >= 576) {
      setExpandedFilters(true);
    }
  }, [windowWidth]);

  useEffect(() => {
    const normalizeText = (text: string) => {
      return text.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    };

    setLoading(true);

    let newItems: CatalogueItem[] = catalogue;

    if (filters.buscar) {
      const searchTerm = normalizeText(filters.buscar);
      newItems = newItems.filter((item) => (normalizeText(item.title).includes(searchTerm)));
    }

    Object.keys(filters).forEach((key) => {
      if (fieldToPropertyMap.hasOwnProperty(key)) {
        const property = fieldToPropertyMap[key];
        const value = filters[key as keyof OnApplyFiltersFilters];

        newItems = newItems.filter((item) => (
          item.tags &&
          item.tags.hasOwnProperty(property) &&
          item.tags[property as keyof CatalogueItemTags] === value
        ));
      }
    });

    if (newItems.length > 0) {
      setTotalPages(Math.ceil(newItems.length / itemsPerPage));
      const maxItems = (newItems.length > itemsPerPage) ? itemsPerPage : newItems.length;
      const pageIndex = ((currentPage - 1) * itemsPerPage);
      newItems = newItems.slice(pageIndex, maxItems + pageIndex);
    } else {
      setCurrentPage(1);
      setTotalPages(1);
    }

    setItems(newItems);
  }, [catalogue, currentPage, filters]);

  useEffect(() => {
    if (loading) {
      const timeout = setTimeout(() => setLoading(false), 150);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [loading]);

  const handleFiltersExpand = () => {
    if (windowWidth >= 576) {
      return;
    }
    setExpandedFilters(!expandedFilters);
  };

  const handleApplyFilters = (filters: OnApplyFiltersFilters) => {
    setCurrentPage(1);
    setFilters(filters);
  };

  const handleResetFilters = () => {
    setCurrentPage(1);
  };

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  return (
    <>
      <Container className="my-4">
        <h2 className="h3 mb-3 d-md-none">Catálogo 📖</h2>
        <Row className="justify-content-center align-items-start">
          <Col xs={12} sm={4} lg={3}>
            <ContentBox className="mb-2">
              <button
                className="d-flex justify-content-between align-items-center w-100 m-0 p-0 border-0 bg-transparent"
                style={{
                  cursor: `${(windowWidth >= 576) ? "default" : "pointer"}`
                }}
                type="button"
                onClick={handleFiltersExpand}
              >
                <h4><FunnelFill className="me-1" size=".75em" />Filtrar</h4>
                {expandedFilters && (
                  <CaretUpFill className="d-block d-sm-none" />
                )}
                {!expandedFilters && (
                  <CaretDownFill className="d-block d-sm-none" />
                )}
              </button>
              <Collapse className="mt-2" in={expandedFilters}>
                <div>
                  <Filters onApplyFilters={handleApplyFilters} onResetFilters={handleResetFilters} />
                </div>
              </Collapse>
            </ContentBox>
            <ContentBox className="mb-2">
              <h4><CartFill className="me-1" size=".75em" />Carrito</h4>
              <ShoppingCart />
            </ContentBox>
          </Col>
          <Col xs={12} sm={8} lg={9} className="mt-4 mt-sm-0">
            {loading && (
              <Spinner
                style={{
                  width: "4rem",
                  height: "4rem",
                }}
              />
            )}
            {!loading && (
              <>
                {items.length === 0 && (
                  <p className="lead mb-0">No se encontraron resultados para su búsqueda. Pruebe con algo distinto.</p>
                )}
                {items.length > 0 && (
                  <Paginate
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                  >
                    <Row className="justify-content-start align-items-stretch">
                      {items.map((item, idx) => (
                        <Col
                          sm={6}
                          lg={3}
                          className={`${(idx > 0) ? `mt-4 ${(idx < 2) ? "mt-sm-0" : (idx < 4) ? "mt-lg-0" : ""}` : ""}`}
                          key={idx}
                        >
                          <ProductCard item={item}/>
                        </Col>
                      ))}
                    </Row>
                  </Paginate>
                )}
              </>
            )}
          </Col>
        </Row>
      </Container>
    </>
  );

};

export default Catalogue;
